import React from "react";

function About_us() {
  return (
    <section className="about_us" id="About-Us">
      <div className="about_us-container">
        <div className="about_us-left">
          <div className="about_us-left-top"></div>
          <div className="about_us-left-bottom">
            <p className="about_us-pitch">We create unforgettable, luxurious bridal attire to make your special day magical. Our exquisite designs and attention to detail guarantee elegance and sophistication. Walk down the aisle in style and grace with our extraordinary collection.</p>
          </div>
        </div>
        <div className="about_us-right">
          <div className="about_us-right-top">
            <h1 className="about_us-header">About us</h1>
            <p className="about-afom_des">Afom Bridal Company offers a wide range of exquisite bridal gowns and accessories, combining traditional sophistication with contemporary flair.Choose Afom for excellence, creativity, and a touch of magic on your special day. Visit our beautiful boutique and embark on an unforgettable journey towards your dream wedding.</p>
          </div>
          <div className="about_us-right-bottom"></div>
        </div>
      </div>
      
    </section>
  );
}

export default About_us;
