import React, { useState,useEffect} from 'react'
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AdminContext } from '../Context/AuthContext'
import axios from 'axios'
import Loading from '../Components/Loading'
function ProtectedRoute() {

    const {isAuth,setIsAuth}=useContext(AdminContext)
    const [isloading,setisLoading]=useState(true)
    const navigate=useNavigate()




    const getCsrfToken = () => {
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
    return cookieValue || '';
  };
  useEffect(()=>{

    const reftoken = document.cookie
        .split('; ')
        .find(row => row.startsWith('refresh_token='))
        ?.split('=')[1];
    const acstoken = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

    const authUser=async ()=>{

      await axios.post(`${process.env.REACT_APP_URL}/api/token/refresh/`,{refresh:reftoken},{headers:{
        'X-CSRFToken': getCsrfToken(),
        "Authorization":`Bearer ${acstoken}`
      }}).then(res=>{
        document.cookie=`access_token=${res.data.access}`
        document.cookie=`refresh_token=${res.data.refresh}`
        setIsAuth(true)
        setisLoading(false)

        return console.log(res);
      }).catch(err=>{
        if(err.response.status===401 || err.response.status===400){
          navigate("/adminauth")
        }
      })
    }

    authUser()
  },[])

    if(isloading){
        return <Loading/>
    }else{

        return isAuth? <Outlet/> : <Navigate to="/adminauth"/>
    }
    
  
}

export default ProtectedRoute
