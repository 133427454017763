import react from 'react'

function Fixed_bg(){
    return (
        <div className="fixed_background">
        <div className="fixed_background-content">
          <h1 className="fixed_background-slogan">
          Elevate Your Style with AFOM - Where Fashion Meets Imagination!
          </h1>
          <p className="fixed_background-aboutFashion">
          Fashion is a sartorial art form that allows us to express our individuality and creativity. It is a dynamic and ever-evolving industry that has the power to influence society. Fashion is not just about clothes, it's about the aesthetic, the panache, and the elegance that comes with it. It's a way of life.
          </p>
        </div>
      </div>
    )
}

export default Fixed_bg;