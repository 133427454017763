import React from 'react'

function Notfound() {
  return (
    <div className='notfound-body'>
      <h1 className='notfound'>404</h1>
      <p>Page Not Found</p>
    </div>
  )
}

export default Notfound
