import React from 'react'
import Logo from "../assets/Logo.png"
function Loading() {
  return (
    <div className='loading_page'>
        <div className="loading_img_container">
            <img src={Logo} alt="logo"  className='loading_logo'/>
        </div>
    </div>
  )
}

export default Loading
