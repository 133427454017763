import React from 'react'

function Loader() {
  return (
    <div className="afom-logo-sqr">
          <div className="logo-back">
            
          </div>
        </div>
  )
}

export default Loader
